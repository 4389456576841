import { CalendarSettings } from '@trovata/app/shared/models/date-range-picker.model';
import { DateTime } from 'luxon';

export const updateForecastsCalendarSettingsDates = (settings: CalendarSettings): void => {
	let startDate: string;
	let endDate: string;
	if (!settings?.isRolling) {
		startDate = settings.startDate;
		endDate = settings.endDate;
	} else {
		const currentDate: DateTime = DateTime.now();
		let lStartDate: DateTime;
		if (settings.periodsOffset < 0) {
			lStartDate = currentDate.minus({ days: Math.abs(settings.periodsOffset) });
		} else {
			lStartDate = currentDate.plus({ days: settings.periodsOffset });
		}
		const lEndDate: DateTime = lStartDate.plus({ days: settings.periods - 1 });
		startDate = lStartDate.toFormat('yyyy-MM-dd');
		endDate = lEndDate.toFormat('yyyy-MM-dd');
	}
	settings.startDate = startDate;
	settings.endDate = endDate;
};
